import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { divide, svgOuterHeight, svgPosition } from './utils';
/**
 * canvas에다 canvas-draw라는 돔을 만들고 targets에서 target의 중심 위치값을 가진 render를 그린다.
 */
export class SimpleDomToDomText {
    id;
    canvasElm;
    svgElm;
    settings;
    static defaultOptions = {
        style: `
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `,
    };
    targets;
    constructor(props) {
        this.id = props.id;
        this.canvasElm = props.canvas;
        this.svgElm = props.svg;
        this.settings = { ...SimpleDomToDomText.defaultOptions, ...props.options };
        this.targets = props.targets;
        this.init();
    }
    init() {
        this.draw();
    }
    render({ target, render, options }) {
        const { direaction, extendStyle, style } = options || { direaction: 'left' };
        const targetMiddle = pipe(target, svgOuterHeight, divide(2));
        const targetY = targetMiddle + pipe(target, svgPosition(this.svgElm), (e) => e.top);
        const targetLeft = pipe(target, svgPosition(this.svgElm), (e) => e.left);
        const renderElm = document.createElement('div');
        renderElm.innerHTML = render;
        let styles = `
      position: absolute;
      top: ${targetY}px;
      left: ${targetLeft}px;
    `;
        if (direaction === 'left') {
            styles += `
        transform: translate(-100%, -50%);
      `;
        }
        if (direaction === 'right') {
            styles += `
        transform: translate(0, -50%);
      `;
        }
        styles += extendStyle();
        if (style && typeof style === 'function') {
            styles = style(targetLeft, targetY);
        }
        renderElm.setAttribute('style', styles);
        return renderElm;
    }
    draw() {
        // clean
        const oldDraw = this.canvasElm.querySelector(`#canvas-draw-${this.id}`);
        if (oldDraw) {
            oldDraw.remove();
        }
        // make
        const canvasDraw = document.createElement('div');
        canvasDraw.id = `canvas-draw-${this.id}`;
        canvasDraw.setAttribute('style', this.settings.style);
        canvasDraw.setAttribute('class', 'pointer-events-none z-10');
        pipe(this.targets, 
        // this.render에 this를 바인드해줘야한다.
        A.map((target) => this.render(target)), 
        // A.map(this.render),
        (e) => e, A.map((elm) => canvasDraw.appendChild(elm)));
        // draw
        this.canvasElm.appendChild(canvasDraw);
    }
}
