import { pipe } from 'fp-ts/function';
import { position, outerHeight, add, divide, subtract, outerWidth, isMaxWidth, } from './utils';
export class SimpleFlowVertical {
    canvasElm;
    settings;
    static defaults = {
        lineWidth: () => 2,
        lineSpacerWidth: 15,
        lineColour: '#91acb3',
        markerWidth: 3,
        markerDirection: 'down',
        svgStyle: '',
        xStartAdjustment: () => 0,
        xEndAdjustment: () => -28,
    };
    targets;
    id;
    drawCallback;
    constructor({ id, canvas, targets, options, drawCallback, }) {
        this.id = id;
        this.canvasElm = canvas;
        this.settings = { ...SimpleFlowVertical.defaults, ...options };
        this.targets = targets;
        this.drawCallback = drawCallback;
        this.init();
    }
    init() {
        this.draw(this.drawCallback);
        window.addEventListener('resize', () => this.draw(this.drawCallback));
    }
    drawMarker(_prefix, _className) {
        const className = _className ? `class="${_className}"` : '';
        const prefix = _prefix ? `${_prefix}-` : '';
        // string to element
        const marker = `
      <marker
        id="${this.id}-${prefix}up"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="3"
        markerHeight="3"
        orient="-90deg">
        <path d="M 4 0 L 10 5 L 4 10 z" fill="currentColor" stroke-fill="currentColor" ${className} />
      </marker>
      <marker
        id="${this.id}-${prefix}right"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="3"
        markerHeight="3"
        orient="0deg">
        <path d="M 4 0 L 10 5 L 4 10 z" fill="currentColor" ${className} />
      </marker>
      <marker
        id="${this.id}-${prefix}down"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="3"
        markerHeight="3"
        orient="90deg">
        <path d="M 4 0 L 10 5 L 4 10 z" fill="currentColor" ${className} />
      </marker>
      <marker
        id="${this.id}-${prefix}left"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="6"
        markerHeight="6"
        orient="180deg">
        <path d="M 4 0 L 10 5 L 4 10 z" fill="currentColor" ${className} />
      </marker>
    `;
        const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
        defs.innerHTML = marker;
        return defs;
    }
    drawLine(thisElm, nextElm, pathProps = {}) {
        // pathProps
        const { prefix, color, markerDirection, xStartAdjustment: _xStartAdjustment, xEndAdjustment: _xEndAdjustment, key, ...props } = pathProps;
        // _xAdjustment는 0이면 false로 인식되기 때문에 0일때도 체크해줘야한다.
        // undefined이면 this.settings.xAdjustment를 사용한다.
        let xStartAdjustment = _xStartAdjustment?.() || Number(_xStartAdjustment?.()) === 0
            ? Number(_xStartAdjustment?.())
            : this.settings.xStartAdjustment();
        let xEndAdjustment = _xEndAdjustment?.() || Number(_xEndAdjustment?.()) === 0
            ? Number(_xEndAdjustment?.())
            : this.settings.xEndAdjustment();
        xEndAdjustment = xEndAdjustment * -1;
        // 모바일일때는 xStartAdjustment, xEndAdjustment를 반으로 줄인다.
        if (isMaxWidth(1024)) {
            xStartAdjustment = xStartAdjustment / 2.3;
            xEndAdjustment = xEndAdjustment / 2.3;
        }
        const thisElmMiddle = pipe(thisElm, outerHeight);
        // const nextElmMiddle = pipe(nextElm, outerHeight)
        const thisParentPadding = pipe(thisElm, outerHeight) - pipe(thisElm, outerHeight);
        let thisElmY = thisElmMiddle +
            pipe(thisElm, position(this.canvasElm)).top -
            thisParentPadding;
        let nextElmY = pipe(nextElm, position(this.canvasElm)).top;
        // thisElmY가 nextElmY보다 크면 nextElm의 top에 끝나도록
        if (thisElmY > nextElmY) {
            nextElmY = nextElmY + pipe(nextElm, outerHeight) + xEndAdjustment;
            thisElmY = thisElmY - thisElmMiddle - xStartAdjustment;
        }
        else {
            nextElmY = nextElmY - xEndAdjustment;
            thisElmY = thisElmY + xStartAdjustment;
        }
        const nextMiddle = pipe(pipe(nextElm, position(this.canvasElm)).left, add(pipe(nextElm, outerWidth, divide(2))));
        const thisMiddle = nextMiddle;
        const farLeftX = nextMiddle;
        const farRightX = thisMiddle;
        const lineInBetweenY = pipe(nextElmY, subtract(pipe(thisParentPadding, divide(2))));
        // TODO:
        // X축이같고 Y축이 다를 때 점은 2개이다.
        // X축과 Y축 모두 다를때 점은 4개이다.
        const coords = `${thisMiddle}, ${thisElmY} ${farRightX}, ${thisElmY} ${farRightX}, ${lineInBetweenY} ${farLeftX}, ${lineInBetweenY} ${farLeftX}, ${nextElmY} ${nextMiddle}, ${nextElmY}`;
        // path string을 elment로 변환
        // `<path d="M ${coords} "style="fill:none;stroke: currentColor; stroke-width: ${this.settings['lineWidth']}; stroke-linecap: round; marker-end:url(#arrowhead);" stroke-dasharray="4" />`
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.id = key || '';
        path.setAttribute('d', `M ${coords} `);
        path.setAttribute('style', `fill:none;stroke: currentColor; stroke-width: ${this.settings['lineWidth']()}; stroke-linecap: square; marker-end:url(#${markerDirection || this.settings['markerDirection']});`);
        if (props) {
            Object.keys(props).forEach((key) => {
                path.setAttribute(key, props[key]);
            });
        }
        if (color && prefix) {
            // create <g> element
            const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            const marker = this.drawMarker(prefix, color);
            path.setAttribute('style', `fill:none;stroke: currentColor; stroke-width: ${this.settings['lineWidth']()}; stroke-linecap: square; marker-end:url(#${this.id}-${prefix}-${markerDirection || this.settings['markerDirection']});`);
            group.appendChild(marker);
            group.appendChild(path);
            return group;
        }
        return path;
    }
    draw(cb) {
        // clean
        const oldSvg = this.canvasElm.querySelector(`svg#${this.id}`);
        if (oldSvg) {
            oldSvg.remove();
        }
        const defs = this.drawMarker();
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('id', this.id);
        svg.setAttribute('class', 'simple-flow-line absolute top-0 left-0 w-full h-full pointer-events-none z-10');
        svg.setAttribute('style', this.settings['svgStyle']);
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '100%');
        svg.appendChild(defs);
        this.targets.forEach(({ from, to, options }) => {
            const path = this.drawLine(from, to, options);
            svg.appendChild(path);
        });
        this.canvasElm.appendChild(svg);
        cb?.(this.canvasElm.querySelector(`svg#${this.id}`));
    }
}
// Usage example:
// const element = document.getElementById('yourElementId')
// const simpleFlow = new SimpleFlow(element, { lineWidth: 3 })
