import header from '../components/header';
import { copylightLog } from '@beeclover/copylight';
export default {
    containerInit() {
        copylightLog();
    },
    init() {
        header();
        // container init
        this.containerInit();
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
