import { SimpleFlowHorizontal } from '~/libs/simple-flow-horizontal';
import { SimpleFlowVertical } from '~/libs/simple-flow-vertical';
import { isMaxWidth } from '~/libs/utils';
export default {
    init() {
        const canvas = document.querySelector('#flow-canvas');
        new SimpleFlowVertical({
            id: 'id1',
            canvas: canvas,
            targets: [
                {
                    from: document.querySelector('[data-from-3]'),
                    to: document.querySelector('[data-to-4]'),
                    options: {
                        class: 'text-third',
                        color: 'text-third',
                        prefix: 'third',
                    },
                },
                {
                    from: document.querySelector('[data-from-6]'),
                    to: document.querySelector('[data-to-7]'),
                    options: {
                        class: 'text-primary',
                        color: 'text-primary',
                        prefix: 'primary',
                    },
                },
            ],
            options: {
                lineWidth: () => {
                    if (isMaxWidth(1024)) {
                        return 4;
                    }
                    return 8;
                },
                markerDirection: 'down',
                xStartAdjustment: () => {
                    if (isMaxWidth(1024)) {
                        return 24;
                    }
                    return 15;
                },
                xEndAdjustment: () => {
                    if (isMaxWidth(1024)) {
                        return -24;
                    }
                    return -20;
                },
            },
        });
        new SimpleFlowHorizontal({
            id: 'id2',
            canvas: canvas,
            targets: [
                {
                    from: document.querySelector('[data-from-1]'),
                    to: document.querySelector('[data-to-2]'),
                    options: {
                        class: 'text-primary',
                        color: 'text-primary',
                        prefix: 'primary',
                    },
                },
                {
                    from: document.querySelector('[data-from-2]'),
                    to: document.querySelector('[data-to-3]'),
                    options: {
                        class: 'text-secondary',
                        color: 'text-secondary',
                        prefix: 'secondary',
                    },
                },
                {
                    from: document.querySelector('[data-from-4]'),
                    to: document.querySelector('[data-to-5]'),
                    options: {
                        class: 'text-third',
                        color: 'text-third',
                        prefix: 'third',
                        markerDirection: 'left',
                    },
                },
                {
                    from: document.querySelector('[data-from-5]'),
                    to: document.querySelector('[data-to-6]'),
                    options: {
                        class: 'text-secondary',
                        color: 'text-secondary',
                        prefix: 'secondary',
                        markerDirection: 'left',
                    },
                },
            ],
            options: {
                lineWidth: () => {
                    if (isMaxWidth(1024)) {
                        return 4;
                    }
                    return 8;
                },
                markerDirection: 'right',
                xStartAdjustment: () => {
                    if (isMaxWidth(1024)) {
                        return 8;
                    }
                    return 15;
                },
                xEndAdjustment: () => {
                    if (isMaxWidth(1024)) {
                        return 12;
                    }
                    return 20;
                },
            },
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
