export function svgPosition(svgElement) {
    return function (elm) {
        const rect = elm.getBoundingClientRect();
        const svgRect = svgElement.getBoundingClientRect();
        const top = rect.top - svgRect.top;
        const left = rect.left - svgRect.left;
        const bottom = rect.top + rect.height;
        const right = rect.height;
        return {
            top,
            left,
            bottom,
            right,
        };
    };
}
// jquery `.outerWidth()` 컨버팅
export function svgOuterWidth(elm) {
    const { width } = elm.getBoundingClientRect();
    return width;
}
// jquery `.outerHeight()` 컨버팅
export function svgOuterHeight(elm) {
    const { height } = elm.getBoundingClientRect();
    return height;
}
export function position(canvas) {
    return function (elm) {
        let currentElement = elm;
        let offsetX = 0;
        let offsetY = 0;
        while (currentElement) {
            offsetX += currentElement.offsetLeft || 0;
            offsetY += currentElement.offsetTop || 0;
            if (currentElement.offsetParent === canvas) {
                break;
            }
            currentElement = currentElement.offsetParent;
        }
        const w = elm.offsetWidth || 0;
        const h = elm.offsetHeight || 0;
        return {
            top: offsetY,
            left: offsetX,
            bottom: offsetY + h,
            right: offsetX + w,
        };
    };
}
// jquery `.outerWidth()` 컨버팅
export function outerWidth(elm) {
    const width = elm.offsetWidth;
    return width;
}
// jquery `.outerHeight()` 컨버팅
export function outerHeight(elm) {
    const height = elm.offsetHeight;
    return height;
}
// 더하기
export function add(a) {
    return (b) => b + a;
}
// 나누기
export function divide(a) {
    return (b) => b / a;
}
// 빼기
export function subtract(a) {
    return (b) => b - a;
}
export function isMaxWidth(size) {
    const width = window.innerWidth;
    if (width <= size) {
        return true;
        // 여기에 너비가 600px 이하일 때 수행할 동작을 추가
    }
    // 여기에 너비가 600px 초과일 때 수행할 동작을 추가
    return false;
}
