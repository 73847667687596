/* eslint-disable no-confusing-arrow */
import { SimpleDomToDomText } from '~/libs/simple-dom-to-dom-text';
import { SimpleFlowHorizontal } from '~/libs/simple-flow-horizontal';
import { SimpleFlowVertical } from '~/libs/simple-flow-vertical';
import { isMaxWidth } from '~/libs/utils';
function drawFlowsText(canvas) {
    return function (svg) {
        const flow1 = svg.querySelector('#flow1');
        const flow2 = svg.querySelector('#flow2');
        const flow4 = svg.querySelector('#flow4');
        new SimpleDomToDomText({
            id: 'flows-text',
            canvas,
            svg,
            targets: [
                {
                    target: flow1,
                    render: `
            <div class="font-bold text-gray-mid text-right max-lg:text-[10px] tracking-[-0.2px]">
              Research<br class="xs:hidden" /> Items<br/>
              Mentoring<br/>
              Evaluation
            </div>
          `,
                    options: {
                        extendStyle: () => isMaxWidth(1024)
                            ? `
                  transform: translate(calc((100% + 7px) * -1), -80%);
                `
                            : `
                  transform: translate(calc((100% + 21px) * -1), -80%);
                `,
                    },
                },
                {
                    target: flow2,
                    render: `
            <div class="font-bold text-secondary text-right max-lg:text-[10px]">
              Student<br/>
              Proposals
            </div>
          `,
                    options: {
                        extendStyle: () => isMaxWidth(1024)
                            ? `
                    transform: translate(calc((100% + 7px) * -1), -50%);
                `
                            : `
                  transform: translate(calc((100% + 21px) * -1), -50%);
                `,
                    },
                },
                {
                    target: flow4,
                    render: `
            <div class="font-bold text-primary text-left max-lg:text-[10px]">
              Research<br/>
              Actions
            </div>
          `,
                    options: {
                        extendStyle: () => isMaxWidth(1024)
                            ? `
              transform: translate(7px, -50%);
              `
                            : `
              transform: translate(21px, -50%);
            `,
                    },
                },
            ],
        });
    };
}
function drawFlows(canvas) {
    new SimpleFlowVertical({
        id: 'id1',
        canvas,
        targets: [
            {
                from: document.querySelector('[data-from1]'),
                to: document.querySelector('[data-to1]'),
                options: {
                    key: 'flow1',
                    class: 'text-gray-mid',
                    color: 'text-gray-mid',
                    prefix: 'gray-mid',
                    markerDirection: 'down',
                    xEndAdjustment: () => 15,
                },
            },
            {
                from: document.querySelector('[data-from2]'),
                to: document.querySelector('[data-to2]'),
                options: {
                    key: 'flow2',
                    class: 'text-secondary',
                    color: 'text-secondary',
                    prefix: 'secondary',
                    xEndAdjustment: () => 15,
                },
            },
            {
                from: document.querySelector('[data-from3]'),
                to: document.querySelector('[data-to3]'),
                options: {
                    key: 'flow3',
                    class: 'text-primary',
                    color: 'text-primary',
                    prefix: 'primary',
                    xEndAdjustment: () => 15,
                },
            },
            {
                from: document.querySelector('[data-from4]'),
                to: document.querySelector('[data-to4]'),
                options: {
                    key: 'flow4',
                    class: 'text-primary',
                    color: 'text-primary',
                    prefix: 'primary',
                    xEndAdjustment: () => 15,
                },
            },
            {
                from: document.querySelector('[data-from5]'),
                to: document.querySelector('[data-to5]'),
                options: {
                    key: 'flow5',
                    class: 'text-primary',
                    color: 'text-primary',
                    prefix: 'primary',
                },
            },
            {
                from: document.querySelector('[data-from6]'),
                to: document.querySelector('[data-to6]'),
                options: {
                    key: 'flow6',
                    class: 'text-[#D2D4DA]',
                    color: 'text-[#D2D4DA]',
                    prefix: '[#D2D4DA]',
                },
            },
            {
                from: document.querySelector('[data-from7]'),
                to: document.querySelector('[data-to7]'),
                options: {
                    key: 'flow7',
                    class: 'text-primary',
                    color: 'text-primary',
                    prefix: 'primary',
                    markerDirection: 'down',
                    xEndAdjustment: () => 15,
                },
            },
        ],
        options: {
            lineWidth: () => {
                if (isMaxWidth(1024)) {
                    return 5;
                }
                return 10;
            },
            markerDirection: 'up',
            xStartAdjustment: () => {
                if (isMaxWidth(1024)) {
                    return 25 / 2.3;
                }
                return 25;
            },
            xEndAdjustment: () => {
                if (isMaxWidth(1024)) {
                    return -28 / 2.3;
                }
                return -28;
            },
        },
        drawCallback: drawFlowsText(canvas),
    });
    new SimpleFlowHorizontal({
        id: 'id2',
        canvas,
        targets: [
            {
                from: document.querySelector('[data-from8]'),
                to: document.querySelector('[data-to8]'),
                options: {
                    class: 'text-gray-mid',
                    color: 'text-gray-mid',
                    prefix: 'gray-mid',
                    markerDirection: 'right',
                },
            },
            {
                from: document.querySelector('[data-from9]'),
                to: document.querySelector('[data-to9]'),
                options: {
                    class: 'text-gray-mid',
                    color: 'text-gray-mid',
                    prefix: 'gray-mid',
                    markerDirection: 'left',
                },
            },
        ],
        options: {
            lineWidth: () => {
                if (isMaxWidth(1024)) {
                    return 5;
                }
                return 10;
            },
            xStartAdjustment: () => {
                if (isMaxWidth(1024)) {
                    return -2;
                }
                return 25;
            },
            xEndAdjustment: () => {
                if (isMaxWidth(1024)) {
                    return 4;
                }
                return 28;
            },
            reverse: () => {
                if (isMaxWidth(1024)) {
                    return true;
                }
                return false;
            },
        },
    });
}
export default {
    init() {
        const canvas = document.querySelector('.canvas');
        drawFlows(canvas);
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
